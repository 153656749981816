import { asyncThrottle } from './asyncThrottle';
export const createAsyncStoragePersister = ({
  storage,
  key = "REACT_QUERY_OFFLINE_CACHE",
  throttleTime = 1000,
  serialize = JSON.stringify,
  deserialize = JSON.parse
}) => {
  return {
    persistClient: asyncThrottle(persistedClient => storage.setItem(key, serialize(persistedClient)), {
      interval: throttleTime
    }),
    restoreClient: async () => {
      const cacheString = await storage.getItem(key);

      if (!cacheString) {
        return;
      }

      return deserialize(cacheString);
    },
    removeClient: () => storage.removeItem(key)
  };
};